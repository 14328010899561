<template>
  <!-- 获取id 需要修改 -->
  <!-- v-loading.fullscreen.lock="fullscreenLoading" -->
  <div
    class="tc-report"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- 页面功能 -->
    <div class="tool">
      <ul>
        <li>
          <img
            src="../../../assets/images/refresh.png"
            @click="refresh()"
            alt
          />
        </li>
        <li>
          <img
            src="../../../assets/images/retreatBtn.png"
            @click="back()"
            alt
          />
        </li>
      </ul>
    </div>
    <!-- 头部工具 -->
    <div
      class="tc-report-header"
      v-if="$route.path.split('/')[1] != 'answer_content'"
    >
      <router-link target="_blank" :to="{ path: '/condownpdf', query: uid }">
        <el-button type="primary" plain>打印报告</el-button>
      </router-link>
      <!-- <el-divider direction="vertical"></el-divider>
      <el-button type="primary" plain @click="showSetReportBox"
        >打印设置</el-button
      > -->
      <!-- <el-divider direction="vertical"></el-divider> -->
      <!-- <el-button type="primary" plain @click="infoEditState" v-if="!editState"
        >编辑内容</el-button
      > -->
      <!-- <el-button type="primary" plain @click="seveEditInfo" v-if="editState"
        >保存</el-button
      > -->
      <!-- <el-divider direction="vertical"></el-divider> -->
      <!-- <el-button type="primary" plain @click="downWord">导出Word</el-button> -->
    </div>
    <!-- 内容主题 -->

    <div v-show="true" id="ccc">
      <div class="tc-report-main">
        <div class="content">
          <div class="report-title">
            <h1>精神科临床常用量表测验报告单</h1>
          </div>
          <div class="title-info">
            <div class="unit">
              <span>使用单位:</span>
              <span> {{ config.unit_name }}</span>
            </div>
            <div class="user-info">
              <span>姓名:{{ form.name }}</span>
              <span>性别:{{ form.sex }}</span>
              <span>年龄:{{ form.ages }}岁</span>
              <span>教育:{{ form.culture }}</span>
              <!-- <span>婚姻:{{ form.name }}</span> -->
              <!-- <span>住院号:{{ form.name }}</span>
              <span>病区:{{ form.name }}</span> -->
            </div>
          </div>
          <div class="table">
            <table border="1" width="100%" cellspacing="0" cellpadding="0">
              <thead>
                <tr>
                  <th width="30%">量表</th>
                  <th>项目</th>
                  <th>记分</th>
                  <th>程度</th>
                </tr>
              </thead>
              <tbody
                align="center"
                v-for="(item, index) of tableInfoList"
                :key="index"
              >
                <tr v-for="(subitem, cindex) of item" :key="cindex">
                  <td width="30%" :rowspan="item.length" v-if="cindex == 0">
                    {{ subitem.measure_title }}
                  </td>

                  <td width="30%">{{ subitem.name }}</td>
                  <td width="20%">{{ subitem.score }}</td>
                  <td width="30%">{{ subitem.mark }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style="
              border-top: 1px solid #000;
              padding: 15px;
              text-align: center;
            "
          >
            <h2>临床综合分析</h2>
          </div>
          <div class="table2">
            <!-- <div v-for="(item, index) of tableInfoList" :key="index">
              <div>{{ item[0].measure_title }}:</div>
              <div style="display: flex; flex-wrap: wrap">
                <div v-for="(subitem, cindex) of item" :key="cindex">
                  <div>{{ cindex + 1 }}、{{ subitem.comment }}</div>
                </div>
              </div>
            </div> -->
            <div v-for="(item, index) of tableInfoList2" :key="index">
              <div
                v-html="index + 1 + '、' + removeHTMLTag(item.comment)"
              ></div>
            </div>
          </div>
          <div style="text-align: center; font-size: 20px">
            (本报告仅供参考)
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
import $ from "jquery";
import { loadReport, save_meaconclusion } from "@/api/report.js";
import { max } from "moment";
import { updateConfig } from "@/api/comm.js";
import saveAs from "file-saver";
import jszip from "jszip";
import moment from "moment";
import "../../../../public/static/jquery.wordexport";
import { log } from "util";

export default {
  inject: ["reload"],
  data() {
    return {
      uid: {},
      // 表数据
      tableInfoList: [],
      tableInfoList2: [],
      itemlist: [],
      form: {},
      measure_title: "",
    };
  },
  beforeCreate() {},
  created() {
    // 获取全局配置项
    this.config = JSON.parse(localStorage.getItem("config"));
    //  获取用户id.判断单个还是多个id
    let data = {
      id: "",
    };
    if (this.$route.query.id) {
      // console.log("单");
      data.id = this.$route.query.id;
      this.uid.id = this.$route.query.id;
    } else if (this.$route.query.ids) {
      // console.log("多");
      data.id = this.$route.query.ids;
      this.uid.ids = this.$route.query.ids;
      // console.log(this.$route.query.ids.split(",").length == 1);
      if (this.$route.query.ids.split(",").length == 1) {
      }
    }
    this.loadReport(data);

    // 发起请求渲染报告
  },
  mounted() {
    // this.charData();
    this.$nextTick(() => {
      // this.charData();
      // 字体大小设置
      let fontS =
        JSON.parse(localStorage.getItem("config")).report_font_size + "px";
      this.fontSize = fontS;
      // ----------------------
    });
  },
  computed: {
    removeHTMLTag() {
      return function (str) {
        // str = str.replace(/<\/?[^>]*>/g, ""); //去除HTML tag
        // str = str.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
        // str = str.replace(/\n[\s| | ]*\r/g, "\n"); //去除多余空行
        // str = str.replace(/ /gi, ""); //去掉
        str = str.replace(/&nbsp;/gi, "");
        return str;
      };
    },
  },
  filters: {},
  methods: {
    loadReport(data) {
      loadReport(data).then((res) => {
        if (Object.prototype.toString.call(res.data) == "[object Object]") {
          this.form = res.data.customer;
          if (
            Object.prototype.toString.call(res.data.base_results) ==
            "[object Object]"
          ) {
            if (
              Object.prototype.toString.call(res.data.factor_results) ==
              "[object Object]"
            ) {
              let obj = Object.assign(
                res.data.base_results,
                res.data.factor_results
              );
              obj.measure_title = res.data.measure_title;
              this.itemlist.push(obj);
            } else {
              let newarr = Array.from(res.data.factor_results);
              newarr.forEach((item) => {
                item.measure_title = res.data.measure_title;
              });
              let obj = Object.assign({}, res.data.base_results);
              obj.measure_title = res.data.measure_title;
              newarr.push(obj);
              this.itemlist.push(...newarr);
              debugger;
            }
          } else {
            if (
              Object.prototype.toString.call(res.data.factor_results) ==
              "[object Object]"
            ) {
              let newarr = Array.from(res.data.base_results);
              newarr.forEach((item) => {
                item.measure_title = res.data.measure_title;
              });
              let obj = Object.assign({}, res.data.factor_results);
              obj.measure_title = res.data.measure_title;
              newarr.push(obj);
              this.itemlist.push(...newarr);
              debugger;
            } else {
              let newarr2 = res.data.base_results.concat(
                res.data.factor_results
              );

              // 阳性与阴性症状量表
              if (res.data.measure_title == "阳性与阴性症状量表(PANSS)") {
                newarr2 = newarr2.slice(18, 24);
              }

              let newarr = newarr2.filter((item, index, arr) => {
                return (item.measure_title = res.data.measure_title);
              });

              this.itemlist.push(...newarr);
            }
          }
        } else {
          this.form = res.data[0].customer;
          for (let i of res.data) {
            if (
              Object.prototype.toString.call(i.base_results) ==
              "[object Object]"
            ) {
              if (
                Object.prototype.toString.call(i.factor_results) ==
                "[object Object]"
              ) {
                let obj = Object.assign(i.base_results, i.factor_results);
                obj.measure_title = i.measure_title;
                this.itemlist.push(obj);
              } else {
                let newarr = Array.from(i.factor_results);
                newarr.forEach((item) => {
                  item.measure_title = i.measure_title;
                });
                let obj = Object.assign({}, i.base_results);
                obj.measure_title = i.measure_title;
                newarr.push(obj);
                this.itemlist.push(...newarr);
                // debugger;
              }
            } else {
              if (
                Object.prototype.toString.call(i.factor_results) ==
                "[object Object]"
              ) {
                let newarr = Array.from(i.base_results);
                newarr.forEach((item) => {
                  item.measure_title = i.measure_title;
                });
                let obj = Object.assign({}, i.factor_results);
                obj.measure_title = i.measure_title;
                newarr.push(obj);
                this.itemlist.push(...newarr);
              } else {
                let newarr2 = i.base_results.concat(i.factor_results);
                // 阳性与阴性症状量表
                if (i.measure_title == "阳性与阴性症状量表(PANSS)") {
                  newarr2 = newarr2.slice(18, 24);
                }

                let newarr = newarr2.filter((item, index, arr) => {
                  return (item.measure_title = i.measure_title);
                });
                this.itemlist.push(...newarr);
              }
            }
          }
        }
        let sorted = this.groupBy(this.itemlist, function (item) {
          return [item.measure_title];
        });
        this.tableInfoList = sorted;
        let arr = [];
        for (let i of sorted) {
          for (let j of i) {
            if (j.name == "总分") {
              let obj = {};
              obj.name = j.name;
              obj.comment = j.comment;
              arr.push(obj);
            }
          }
        }
        this.tableInfoList2 = arr;
        console.log(sorted, "sorted");
      });
    },
    //分组
    groupBy(array, f) {
      var groups = {};
      array.forEach(function (o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
        return groups[group];
      });
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
  },
  filters: {},
  watch: {},
};
</script>
<style lang="less">
.tc-report {
  position: relative;
  // 页面功能
  .tool {
    position: absolute;
    top: -50px;
    right: 0;
    overflow: hidden;
    ul {
      li {
        cursor: pointer;
        list-style: none;
        float: left;
        margin-left: 10px;
        img {
          width: 100px;
        }
      }
    }
  }
  //   顶部工具栏
  .tc-report-header {
    padding: 0 10px;
    height: 50px;
    background: rgb(217, 242, 247);
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-right: 50px;
    border-radius: 5px;
    min-width: 860px;
  }
  .SetReport {
    position: absolute;
    top: 20;
    left: 0;
    z-index: 9999;
    background: rgb(201, 239, 247);
    padding: 10px;
    border-radius: 10px;
  }
  // 报告
  .tc-report-main {
    width: 100%;
    // 报告内容部分
    .content {
      max-width: 1200px;
      margin: auto;
      background: white;
      padding: 15px;
      box-sizing: border-box;
      // 报表头部
      .report-title {
        font-size: 26px;
        text-align: center;
      }
      .title-info {
        .unit {
          font-size: 26px;
          color: #000;
          margin-top: 10px;
          margin-bottom: 10px;
          font-weight: 500;
        }
      }
      .user-info {
        display: flex;
        justify-content: space-between;
      }
      // .tableborder {
      //   border-right: 0px;
      // }

      .table {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .table2 {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 20px;
      }

      //   用户信息
      .userInfo {
        margin-top: 20px;
        table {
          margin: auto;
          width: 100%;
          border-top: 1px black dashed;
          border-left: 1px black dashed;
          tr {
            width: 100%;
            // font-size: 16px;
            font-weight: 700;
            td {
              padding: 5px;
              border-bottom: 1px black dashed;
              border-right: 1px black dashed;
              &:nth-child(1) {
                width: 220px;
              }
              &:nth-child(2) {
                width: 180px;
              }
              &:nth-child(3) {
                width: 300px;
              }
              span {
                // font-size: 14px;
                font-weight: 300;
              }
            }
          }
        }
      }
      //   测试结果标题.
      .test-msg {
        padding-left: 0px;
        margin-top: 10px;
        font-size: 25px;
        color: orange;
        text-shadow: black 2px 1px 1px;
        font-weight: 700;
      }
      //    图表
      .chart {
        margin-top: 10px;
        height: 350px;
        #chart-report {
          margin: 0 auto;
          //   border: black 1px solid;
        }
      }
      //   表格
      .table {
        overflow: hidden;
        box-sizing: border-box;
        padding: 20px 0;
        // border-top: 1px black solid;
        // border-bottom: 1px black solid;
        .table-main {
          .one,
          .two {
            width: 100%;
            border-top: 1px black dashed;
            border-left: 1px black dashed;
            tr {
              width: 100%;
              th {
                border-right: 1px black dashed;
                border-bottom: 1px black dashed;
                padding: 5px 0;
              }
              td {
                text-align: center;
                border-right: 1px black dashed;
                border-bottom: 1px black dashed;
                padding: 5px 0;
                font-size: 14px;
                &:nth-child(1) {
                  // width: 180px;
                }
                &:nth-child(2) {
                  // width: 150px;
                }
                &:nth-child(3) {
                  min-width: 40px;
                  text-align: center;
                }
                .table-icon {
                  padding-left: 5px;
                }
              }
            }
          }
          .one {
          }
          .two {
            margin-left: -3px;
            border-left: 1px black dashed;
          }
          .results-two {
            width: 100%;
            border-top: 1px dashed black;
            border-left: 1px dashed black;
            tr {
              th {
                padding: 5px 0;
                border-bottom: 1px dashed black;
                border-right: 1px dashed black;
                &:nth-child(1) {
                  width: 160px;
                }
                &:nth-child(2) {
                  width: 180px;
                }
                &:nth-child(3) {
                  width: 50px;
                }

                &:nth-child(4) {
                  width: 160px;
                }
                &:nth-child(5) {
                  width: 180px;
                }
                &:nth-child(6) {
                  width: 50px;
                }
              }
              td {
                box-sizing: border-box;
                padding: 2px 5px 2px 5px;
                vertical-align: middle;
                border-bottom: 1px dashed black;
                border-right: 1px dashed black;
                text-align: center;
                &:nth-child(1) {
                  width: 160px;
                }
                &:nth-child(2) {
                  width: 180px;
                }
                &:nth-child(3) {
                  width: 50px;
                }

                &:nth-child(4) {
                  width: 160px;
                }
                &:nth-child(5) {
                  width: 180px;
                }
                &:nth-child(6) {
                  width: 50px;
                }
              }
            }
          }
        }
      }
      //   健康状态
      .condition {
        margin-top: 10px;
        border-bottom: 1px black solid;
        padding-bottom: 14px;
        table {
          tr {
            td {
              text-indent: 2em;
              letter-spacing: 3px;
              line-height: 25px;
              // font-size: 15px;
            }
          }
          .condition-title {
            text-indent: 0;
            padding-top: 10px;
            // font-size: 16px;
            font-weight: 700;
          }
        }
      }
      //   每项分析
      .analyze {
        margin-top: 20px;
        padding-bottom: 10px;

        table {
          padding: 10px 0;
          border-bottom: 1px black solid;
          width: 100%;
          tr {
            td {
              text-indent: 2em;
              letter-spacing: 3px;
              line-height: 25px;
              // font-size: 15px;
            }
          }
          .analyze-title {
            text-indent: 0;
            padding-top: 10px;
            // font-size: 16px;
            font-weight: 700;
            .el-input {
              width: 100px;
              &:nth-child(1) {
                width: 180px;
              }
              &:nth-child(2) {
                width: 180px;
              }
              &:nth-child(3) {
                width: 100px;
              }
            }
          }
        }
      }
      .degree {
        margin: 10px 0;
        p {
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
      // 签名
      .signature {
        position: relative;
        text-align: right;
        margin-top: 20px;
        .title {
          font-weight: 700;
          .signature_img {
            display: inline-block;
            min-width: 100px;
          }
        }
        .data {
          margin-left: 20px;
        }
        img {
          // position: absolute;
          // top: -40px;
          // left: 284px;
        }
      }
    }
    // 明尼苏达
    .minnesota {
      .title {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
      }
      .remind {
        border-top: 1px black solid;
        border-bottom: 1px black solid;
        padding: 5px 0;
      }
      .el-divider--horizontal {
        margin: 5px 0;
      }
      .el-divider {
        background-color: rgb(133, 133, 133);
      }
      .mmpi {
        margin-top: 40px;
        .mmpi_table {
          margin-top: 20px;
          table:nth-child(1) {
            width: 100%;
            font-size: 14px;
            tr:nth-child(1) {
              display: flex;
              td {
                flex: 1;
                display: flex;
                justify-content: center;
                &:nth-child(1) {
                  flex: 4;
                  text-align: left;
                  justify-content: flex-start;
                }
                span {
                  display: inline-block;
                  // text-align: center;
                  height: 120px;
                  writing-mode: vertical-lr;
                  letter-spacing: 8px;
                }
              }
            }
            tr:nth-child(2) {
              display: flex;
              td {
                flex: 1;
                display: flex;
                justify-content: center;
                &:nth-child(1) {
                  flex: 4;
                  text-align: left;
                  justify-content: flex-start;
                }
                span {
                  display: inline-block;
                  text-align: center;
                  padding: 5px 0;
                }
              }
            }
          }
          table:nth-child(3) {
            width: 100%;
            font-size: 14px;
            tr {
              display: flex;
              td {
                flex: 1;
                display: flex;
                justify-content: center;
                &:nth-child(1) {
                  flex: 4;
                  text-align: left;
                  justify-content: flex-start;
                }
                span {
                  display: inline-block;
                  text-align: center;
                  padding: 5px 0;
                }
              }
            }
          }
          .mmpi_summarize {
            padding: 10px 0;
          }
        }
      }
      .t {
        margin-top: 40px;
        .t_table {
          margin-top: 20px;
          > table:nth-child(1) {
            width: 100%;
            font-size: 14px;
            tr {
              display: flex;
              > td {
                flex: 1;
                display: flex;
                justify-content: center;
                > span {
                  display: inline-block;
                  text-align: center;
                  padding: 5px 0;
                }
              }
            }
          }
          > table:nth-child(2) {
            width: 100%;
            font-size: 14px;
            border: black 1px solid;
            border-collapse: collapse;
            tr {
              &:nth-child(1) {
                td:nth-child(4) {
                  // display: flex;
                  span {
                    display: inline-block;
                    width: 9%;
                    justify-content: space-around;
                    position: relative;
                    text-align: center;
                    &::after {
                      font-size: 17px;
                      content: "|";
                      position: absolute;
                      bottom: -16px;
                      right: 9px;
                      z-index: 999;
                    }
                    &::before {
                      font-size: 10px;
                      content: "|||||||||";
                      position: absolute;
                      bottom: -11px;
                      right: -3px;
                      z-index: 999;
                    }
                  }
                }
              }
              td {
                text-align: center;
                border-right: black 1px solid;
                border-bottom: black 1px solid;
                &:nth-child(1) {
                  width: 100px;
                }
                &:nth-child(2) {
                  width: 50px;
                }
                &:nth-child(3) {
                  width: 180px;
                }
                &:nth-child(4) {
                  position: relative;
                  text-align: left;
                  font-size: 10px;
                }
                &:nth-child(5) {
                  width: 150px;
                }
              }
            }
            .cursor {
              color: black;
              display: inline-block;
              position: absolute;
              .el-icon-caret-top {
                font-size: 20px;
              }
            }
          }
          .forecast {
            margin-top: 30px;
            table {
              width: 100%;
              caption {
                font-size: 16px;
                font-weight: 700;
                padding: 10px 0;
              }
              tr {
                &:nth-child(2) {
                  font-weight: 700;
                  td {
                    &:nth-child(1) {
                      width: 120px;
                    }
                  }
                }
                td {
                  width: 50px;
                  text-align: right;
                }
              }
            }
          }
        }
      }
      .conclusion {
        margin-top: 40px;
        table {
          width: 100%;
          border-collapse: collapse;
          caption {
            padding: 5px 0;
            font-weight: 700;
            font-size: 18px;
          }
          tr {
            th {
              border-top: 1px black solid;
              border-bottom: 1px black solid;
              padding: 5px 0;
              &:nth-child(1) {
                width: 200px;
                text-align: center;
              }
              &:nth-child(2) {
                width: 150px;
                text-align: center;
              }
              &:nth-child(3) {
              }
            }
            td {
              font-size: 14px;
              padding: 5px 0;
              // vertical-align: top;
              &:nth-child(1) {
              }
              &:nth-child(2) {
                text-align: center;
              }
            }
          }
        }
      }
      .auxiliary {
        margin-top: 40px;
        .auxiliary_title {
          margin-top: 20px;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
        }
        .auxiliary_main {
          .auxiliary_item {
            padding: 10px 0;
            .item_title {
              font-weight: 700;
              margin-bottom: 8px;
            }
          }
        }
      }
      .describe {
        margin-top: 40px;
        .describe_main {
          .describe_content_title {
            font-size: 16px;
            text-align: center;
            p {
              margin: 30px 0px 9px 0px;
              font-size: 16px;
              font-weight: 700;
            }
          }
          .describe_content {
            font-size: 14px;
            padding: 10px 0;
          }
        }
      }
      .zDescribe {
        margin-top: 60px;
        .zDescribe_title {
          font-weight: 700;
          text-align: center;
        }
        .zDescribe_content {
          margin-top: 40px;
          font-size: 14px;
        }
        .zDescribe_msg {
          margin-top: 50px;
          p {
            font-weight: 700;
            span {
              font-weight: 100;
            }
          }
        }
      }
      .suggest {
        border-top: 1px black solid;
        margin-bottom: 5px;
        padding: 5px 0;
        .suggest_msg {
          font-weight: 700;
        }
        .suggest_content {
          height: 200px;
        }
      }
    }
    .report-size {
      font-size: 16px !important;
    }
  }
  .downWordMsg {
    font-size: 12px;
    font-weight: 300;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    position: absolute;
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .tc-report {
    zoom: 0.55;
    overflow: auto;
  }
}
</style>